<template>
  <bg-page>
    <div class="Box">
      <div class="limitBox">
        <div>
          <FirstTitle :myTitle="state.FirstTitle"></FirstTitle>
        </div>
        <div class="SearchBox">
          <div>
            <span class="iconfont iconsousuo"></span>
            <input
              placeholder="请输入关键词搜索"
              type="search"
              v-model="state.title"
            />
          </div>
          <div @click="search">
            <span>搜索</span>
          </div>
        </div>
        <div class="FilterType">
          <FilterType
            :data="state.FilterSearch"
            @GetTypeIndex="GetTypeIndex"
          ></FilterType>
        </div>
        <div class="CulturalList">
          <div
            v-for="(item, index) in state.dataList"
            :key="index"
            class="CulturalItem"
          >
            <div v-if="item.img">
              <el-image
                :src="item.img"
                fit="cover"
                class="cuImage"
                @click="GoToDetails(item)"
                style="width: 100%; height: 160px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div v-else>
              <el-image
                :src="require('../Home/img/logo.png')"
                fit="contain"
                class="cuImage"
                @click="GoToDetails(item)"
                style="width: 100%; height: 160px"
              >
                <template #error>
                  <div class="image-slot">
                    <span>加载中</span>
                  </div>
                </template>
              </el-image>
            </div>
            <div>
              <p @click="GoToDetails(item)">{{ item.title }}</p>
            </div>
            <div>
              <p>
                <span>发布时间：{{ item?.add_time }}</span>
              </p>
              <!-- <p>
                <span class="iconfont iconkejian"></span
                ><span>{{ item.views }}</span>
              </p> -->
            </div>
          </div>
        </div>
        <!--分页-->
        <Pagination
          :config="state.PageConfig"
          @handleCurrentPage="handleCurrentPage"
        ></Pagination>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import FirstTitle from "@/components/FirstTitle/FirstTitle";
import FilterType from "@/components/FilterType/FilterType";
import Pagination from "@/components/Pagination/Pagination";
import api from "@/comment/request/api";
export default {
  name: "Activity",
  components: { FirstTitle, FilterType, Pagination },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      FirstTitle: {
        ChinaName: "文化活动",
        EnglishName: "Cultural Activity",
      },
      FilterSearch: [
        {
          typeName: "活动类别",
          typeList: [],
        },
        {
          typeName: "所属地区",
          typeList: [],
        },
      ],
      PageConfig: {
        currentPage: 1,
        total: 10,
        pageNum: 8,
      },
      dataList: [],
      title: '',
    });
    // 类别
    api.GetActivityCate().success((res) => {
      state.FilterSearch[0].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    })
    // 地区
    api.GetRegionList().success((res) => {
      state.FilterSearch[1].typeList = [{value: null, label: '全部'}].concat(res.data.data);
    });
    const GetTypeIndex = (index, item) => {
      if (index == 0) state.cate_id = item.value;
      if (index == 1) state.code = item.value;
      state.title = "";
      getDataList();
    };
    // 搜索
    const search = () => {
      getDataList();
    };
    const handleCurrentPage = (index) => {
      state.PageConfig.currentPage = index;
      getDataList();
    };
    const GoToDetails = (item) => {
      router.push({
        name: "ActivityDetails",
        query: {
          id: item.activity_id,
        },
      });
    };
    const getDataList = () => {
      const params = {
        page: state.PageConfig.currentPage,
        limit: state.PageConfig.pageNum,
        cate_id: state.cate_id,
        title: state.title,
        code: state.code,
      };
      api.GetActivityList(params).success((res) => {
        state.dataList = res.data.data.data;
        state.PageConfig.total = parseInt(res.data.data.total);
      });
    };
    getDataList();
    return {
      state,
      GetTypeIndex,
      handleCurrentPage,
      search,
      GoToDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.limitBox {
  //padding-bottom: 40px;

  .SearchBox {
    margin-bottom: 10px;
  }
  .FilterType {
    margin-top: 10px;
  }

  .CulturalList {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    border-top: 5px solid #7f0404;

    .CulturalItem {
      width: calc(100% / 4 - 20px);
      margin-top: 40px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-right: 20px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      > div:nth-child(1) {
        padding: 10px;
      }

      > div:nth-child(2) {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        padding: 0 10px;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            color: #7f0404;
          }
        }
      }

      > div:nth-child(3) {
        padding: 10px;
        overflow: hidden;
        height: 30px;
        line-height: 12px;

        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }

        p:nth-child(1) {
          float: left;
        }

        p:nth-child(2) {
          flex: 0 1 auto;
          float: right;
          span:nth-child(2) {
            margin-right: 15px;
          }
          span {
            margin-left: 3px;
          }
        }
      }
    }
  }
}
</style>